const tattoos = [
    {
        title: "รอยสักเท่ห์ๆๆๆๆ",
        thumbnaiUrl:"/images/tattoo-01-small.jpg",
        fullUrl:"/images/tattoo-01-large.jpg"
    },
    {
        title: "มือเท่ห์ๆ",
        thumbnaiUrl:"/images/tattoo-02-small.jpg",
        fullUrl:"/images/tattoo-02-large.jpg"
    },
    {
        title: "คอเท่ห์ๆ",
        thumbnaiUrl:"/images/tattoo-03-small.jpg",
        fullUrl:"/images/tattoo-03-large.jpg"
    },
    {
        title: "หลังเท่ห์ๆ",
        thumbnaiUrl:"/images/tattoo-04-small.jpg",
        fullUrl:"/images/tattoo-04-large.jpg"
    },
    {
        title: "แขนเท่ห์ๆ",
        thumbnaiUrl:"/images/tattoo-05-small.jpg",
        fullUrl:"/images/tattoo-05-large.jpg"
    },
    {
        title: "แขนเท่ห์ๆ",
        thumbnaiUrl:"/images/tattoo-06-small.jpg",
        fullUrl:"/images/tattoo-06-large.jpg"
    }
]

export default tattoos