import './AppHeader.css'

function AppHeader() {
    return (
        <header className="app-header">
                <img className="app-header-logo" src="/images/logo.png" />
            </header>
    )
}

export default AppHeader